import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function initAttendeesSlider() {
  const element = document.getElementById('js-attendees-slider');
  if (element) {
    return tns({
      container: '#js-attendees-slider',
      responsive: {
        350: {
          items: 2
        },
        576: {
          items: 3
        },
        992: {
          items: 4
        }
      },
      items: 4,
      nav: false,
      controlsPosition: 'bottom',
      arrowKeys: true,
      autoHeight: false
    });
  }
}

initAttendeesSlider();
