import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function setSlider() {
  const element = document.getElementById('js-header-slider');
  if (element) {
    return tns({
      container: '#js-header-slider',
      items: 1,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      arrowKeys: true,
      speed: 800,
      autoplay: true,
      autoplayButton: false
    });
  }
}

setSlider();
