import {
  setClassNameNavbar
} from './navigation';
import './header';
import './attendees';
import './share';
import './searchresults';
import './contact';
import './slider';
import './banner';
import './accordion';
import {
  collapseOnMobile,
  toggleBackToTopButton
} from './footer';
import './filters';
import './cookie';

// Footer actions
collapseOnMobile();

// Actions on scroll
window.addEventListener('scroll', () => {
  setClassNameNavbar();
  toggleBackToTopButton();
});

// Actions on resize
window.addEventListener('resize', () => {
  collapseOnMobile();
});
