import {
  tns
} from '../../../../node_modules/tiny-slider/src/tiny-slider';

function initSlider () {
  const slider = document.getElementById('js-slider');
  if (slider) {
    return tns({
      container: '#js-slider',
      items: 1,
      controlsPosition: 'bottom',
      navPosition: 'bottom',
      controlsContainer: false,
      arrowKeys: true,
      autoHeight: false
    });
  }
}

initSlider();
