function openMobileNav() {
  const button = document.querySelector('#js-navigation-toggler'),
    el = document.querySelector('#js-navigation-collapse'),
    lang = document.querySelector('#js-language');
  button.addEventListener('click', () => {
    el.classList.add('is-visible');
    setTimeout(() => {
      lang.classList.remove('fade-in');
      lang.classList.remove('is-visible');
    }, 444);
    setTimeout(() => {
      el.classList.add('slide-enter');
      button.setAttribute('aria-expanded', 'true');
    }, 1);
  });
}

function closeMobileNav() {
  const button = document.querySelector('#js-navigation-cancel'),
    el = document.querySelector('#js-navigation-collapse'),
    hamburgerButton = document.querySelector('#js-navigation-toggler'),
    dropdownItems = document.querySelectorAll('.navigation__menu-item.dropdown');

  button.addEventListener('click', () => {
    el.classList.remove('slide-enter');
    hamburgerButton.setAttribute('aria-expanded', 'false');
    setTimeout(() => {
      el.classList.remove('is-visible');
    }, 444);

    let i;

    function removeDelayed(el) {
      setTimeout(() => {
        el.classList.remove('is-visible');
      }, 255);
    }
    for (i = 0; i < dropdownItems.length; i++) {
      let elements = dropdownItems[i];
      elements.classList.remove('fade');
      elements.setAttribute('aria-expanded', 'false');
      removeDelayed(elements);
    }
  });
}

function setClassNameNavbar() {
  const nav = document.querySelector('#js-navigation');
  if (window.scrollY > 2) {
    nav.classList.add('has-shadow');
  } else {
    nav.classList.remove('has-shadow');
  }
}

function toggleLanguageDropdown() {
  const button = document.querySelector('#js-language-button');
  const wrap = document.querySelector('#js-language');

  button.addEventListener('click', () => {
    if (wrap.classList.contains('is-visible')) {
      wrap.classList.remove('fade-in');
      setTimeout(() => {
        wrap.classList.remove('is-visible');
      }, 200);
    } else {
      closeAllSubnav();
      wrap.classList.add('is-visible');
      setTimeout(() => {
        wrap.classList.add('fade-in');
      });
    }
  });
}

function toggleSubNav() {
  const elements = document.querySelectorAll('.navigation__menu-item.dropdown .navigation__menu-link');

  for (let i = 0; i < elements.length; i++) {
    elements[i].addEventListener('click', (e) => {
      const target = e.target,
            parent = target.closest('.dropdown');

      if (parent.classList.contains('is-visible')) {
        target.setAttribute('aria-expanded', 'false');
        parent.classList.remove('fade');
        setTimeout(() => {
          parent.classList.remove('is-visible');
        }, 255);
      } else {
        closeAllSubnav();
        target.setAttribute('aria-expanded', 'true');
        parent.classList.add('is-visible');
        setTimeout(() => {
          parent.classList.add('fade');
        }, 100);
      }
    });
  }
}

function closeAllSubnav() {
  const elements = document.querySelectorAll('.js-nav-dropdown');
  for (let i = 0; i < elements.length; i++) {
    const target = elements[i];
    const parent = target.closest('.dropdown');
    target.setAttribute('aria-expanded', 'false');
    parent.classList.remove('fade');
    parent.classList.remove('is-visible');

  }
}

// Close menus when clicking outside of them
window.addEventListener('click', (event) => {
  const dropBtns = document.querySelectorAll('.js-nav-dropdown');
  const dropBtnsArray = Array.prototype.slice.call(dropBtns);
  if (dropBtnsArray.filter(e => e === event.target.parentNode).length === 0) {
    closeAllSubnav();
  }
});

openMobileNav();
closeMobileNav();
toggleLanguageDropdown();
toggleSubNav();

export {
  setClassNameNavbar
};
