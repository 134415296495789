function collapseOnMobile() {
  const mediaQuery = window.matchMedia('(max-width: 767px)'),
        viewportWidth = window.innerWidth,
        collapseElements = document.querySelectorAll('.footer__collapse');

  if (mediaQuery.matches && viewportWidth < 768) {
    for (let i = 0; i < collapseElements.length; i++) {
      let collapseContent = collapseElements[i],
          button = collapseContent.getElementsByTagName('button');

      collapseContent.classList.add('is-hidden');
      collapseContent.classList.add('fade-in');
      button[0].setAttribute('aria-expanded', 'false');

      button[0].addEventListener('click', (e) => {
        let target = e.target,
            targetButton = target.closest('button'),
            targetParent = target.closest('div'),
            targetValue = targetButton.getAttribute('aria-expanded');

        if (targetValue === 'false') {
          targetParent.classList.remove('is-hidden');
          setTimeout(() => {
            targetParent.classList.remove('fade-in');
            targetButton.setAttribute('aria-expanded', 'true');
          }, 1);
        } else {
          targetParent.classList.add('fade-in');
          setTimeout(() => {
            targetParent.classList.add('is-hidden');
            targetButton.setAttribute('aria-expanded', 'false');
          }, 301);
        }

      });
    }
  } else {
    for (let i = 0; i < collapseElements.length; i++) {
      let collapseContent = collapseElements[i],
          button = collapseContent.getElementsByTagName('button');

      collapseContent.classList.remove('is-hidden');
      collapseContent.classList.remove('fade-in');
      button[0].setAttribute('aria-expanded', 'true');
    }
  }
}

function scrollToTop() {
  const element = document.querySelector('#js-to-top');
  element.addEventListener('click', () => {
    window.scroll({
      top: 0,
      left: 0
    });
  });
}

function toggleBackToTopButton() {
  const element = document.querySelector('#js-to-top');
  if (window.scrollY == 0) {
    element.classList.add('sr-only');
  } else if (window.scrollY > 100) {
    element.classList.remove('sr-only');
  }
}

scrollToTop();

export {
  collapseOnMobile,
  toggleBackToTopButton
};
